<template>
  <v-layout align-space-around justify-center column fill-height>
    <v-flex xs12>
      <textarea class="source" v-model="source" placeholder="I want to convert, hash, encode, decode or encrypt this text…"></textarea>
    </v-flex>
    <v-flex xs12>
      <v-layout>
        <v-flex xs7 md10>
          <v-select class="fctn" full-width v-model="fctn" placeholder="Apply a function" :items="options" solo item-avatar="avatar" item-text="description" item-value="function"></v-select>
        </v-flex>
        <v-flex xs5 md2>
          <v-checkbox
            v-model="html"
            :label="`As HTML`"
          ></v-checkbox>
        </v-flex>
      </v-layout>
      <iframe v-if="html" class="done" :srcdoc="done" sandbox></iframe>
      <textarea class="done" v-model="done" placeholder="Nothing" v-else></textarea>
    </v-flex>
  </v-layout>
</template>

<script>
  var php = require('locutus/php')
  export default {
    data: () => {
      return {
        source: '',
        fctn: '',
        done: '',
        html: false,
        options: [

          {function: '2', description: '██████████ Encode ██████████', category: 'encode'},
          {function: 'url.base64_encode', description: 'base64_encode($me) - Base 64 encoding', category: 'encode'},
          {function: 'strings.convert_uuencode', description: 'convert_uuencode($me) - UUcode encoding', category: 'encode'},
          {function: 'strings.htmlentities', description: 'htmlentities($me) - HTML encoding', category: 'encode'},
          {function: 'url.urlencode', description: 'urlencode($me) - URL encoding', category: 'encode'},
          {function: 'url.rawurlencode', description: 'rawurlencode($me) - URL encoding', category: 'encode'},
          {function: 'json.json_encode', description: 'json_encode($me) - JSON encoding', category: 'encode'},

          {function: '3', description: '██████████ Decode ██████████', category: 'decode'},
          {function: 'url.base64_decode', description: 'base64_decode($me) - Decode base64 string', category: 'decode'},
          {function: 'strings.convert_uudecode', description: 'convert_uudecode($me) - Decode UUcode', category: 'decode'},
          {function: 'strings.html_entity_decode', description: 'html_entity_decode($me) - Decode HTML entities', category: 'decode'},
          {function: 'url.urldecode', description: 'urldecode($me) - Decode URL', category: 'decode'},
          {function: 'url.rawurldecode', description: 'rawurldecode($me) - Decode URL', category: 'decode'},
          {function: 'json.json_decode', description: 'json_decode($me) - Decode JSON datas', category: 'decode'},

          {function: '4', description: '██████████ Math ██████████', category: 'math'},
          {function: 'math.bindec', description: 'bindec($me) - Convert binary number to decimal number', category: 'math'},
          {function: 'math.decbin', description: 'decbin($me) - Convert decimal number to binary number', category: 'math'},
          {function: 'math.hexdec', description: 'hexdec($me) - Convert hexadecimal number to decimal number', category: 'math'},
          {function: 'math.dechex', description: 'dechex($me) - Convert decimal number to hexadecimal number', category: 'math'},
          {function: 'math.octdec', description: 'octdec($me) - Convert octal number to decimal number', category: 'math'},
          {function: 'math.decoct', description: 'decoct($me) - Convert decimal number to octal number', category: 'math'},
          // {function: '*pi', description: '*pi - Multiplies by pi', category: 'math'},

          {function: '5', description: '██████████ Time ██████████', category: 'time'},
          {function: 'datetime.date', description: 'date($me) - Test a date string with the current timestamp', category: 'time'},
          {function: 'datetime.strtotime', description: 'strtotime($me) - Textual english date into a timestamp', category: 'time'},
          {function: 'custom.datetime.date("r", mystring)', description: 'date("r", $me) - Timestamp to textual date', category: 'time'},
          // {function: 'custom.datetime.date("u")', description: 'date("u") - Current timestamp', category: 'time'},

          {function: '6', description: '██████████ Hash ██████████', category: 'hash'},
          {function: 'strings.md5', description: 'MD5 hash', category: 'hash'},
          {function: 'strings.sha1', description: 'SHA1 hash', category: 'hash'},
          // {function: 'sha256', description: 'SHA256 hash', category: 'hash'},
          // {function: 'sha512', description: 'SHA512 hash', category: 'hash'},
          {function: 'strings.crc32', description: 'CRC32 hash', category: 'hash'}
          ]
      }
    },
    watch: {
      source (newValue) {
        this.process(newValue, this.fctn)
      },
      fctn (newValue) {
        this.process(this.source, newValue)
      }
    },
    methods: {
      process (string, fctn) {
        let usable = false;
        if (fctn.length > 2 && string.length > 0) {
          this.options.some(function(option) {
            if (fctn === option.function) {
              usable = true
              return true
            }
          })
        }
        if (usable === true) {
          try{
            let cat = fctn.split('.')
            if (cat[0] === 'custom') {
              let phpcat = php[cat[1]]
              let pr = cat[2].split('(')
              let args = pr[1].substr(0, pr[1].length-1).split(',')
              args.forEach((arg, idx) => {
                args[idx] = args[idx].trim()
                if (args[idx] == 'mystring') {
                  args[idx] = string
                } else {
                  args[idx].replace('"', '')
                }
              })
              let p = phpcat[pr[0]]
              this.done = p.apply(this, args)
            } else {
              let p = php[cat[0]][cat[1]]
              this.done = p(string)
            }
          } catch(e) {
            this.done = '{ERROR}'
          }
        } else {
          this.done = string
        }
      }
    }
  }
</script>

<style>
textarea.source {
  height: 100%;
  width: 100%;
  padding: 15px;
}
textarea.done {
  height: calc(100% - 85px);
  width: 100%;
  padding: 15px;
}
iframe.done {
  height: calc(100% - 90px);
  width: 100%;
  padding: 5px;
  overflow: auto;
  border: 0;
  font-family: 'Roboto';
}
textarea.done.error {
  color: #A6111F;
} 
</style>
