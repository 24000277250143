<template>
  <v-app>
    <v-toolbar app>
      <v-toolbar-title class="headline">
        <span>strTo</span>
        <span class="font-weight-light">
          (
        <span style="color: #A6111F">{</span>
        <span style="color: #006699"> $me </span>
        <span style="color: #A6111F">}</span>
        )
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        flat
        href="https://www.onlinecreation.pro"
        target="_blank"
      >
        <v-img
          :src="require('./assets/OnlineCreation.svg')"
          contain
          height="30"
        ></v-img>
      </v-btn>
    </v-toolbar>

    <v-content>
      <Main/>
    </v-content>
  </v-app>
</template>

<script>
import Main from './components/Main'

export default {
  name: 'App',
  components: {
    Main
  },
  data () {
    return {
      //
    }
  }
}
</script>
